function handleOverlay() {
    $('.modal').toggleClass('is-visible');
    $('.card').removeClass('selected'); 
}

$('#hk-pin').on('click', function (e) {
    e.preventDefault();
    handleOverlay();
    $('#hk').addClass('selected');
});

$('#sg-pin').on('click', function (e) {
    e.preventDefault();
    handleOverlay();
    $('#sg').addClass('selected');
});

$('#kl-pin').on('click', function (e) {
    e.preventDefault();
    handleOverlay();
    $('#kl').addClass('selected');
});

$('.card__close-btn').click( function (e) {
    e.preventDefault();
    $('.modal').toggleClass('is-visible');
});